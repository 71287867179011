import * as React from "react";

import { Link } from "gatsby";

import ContentContainer from "../components/ContentContainer/ContentContainer";
import Section from "../components/Section/Section";
import MainLayout from "../templates/layouts/MainLayout";

const NotFoundPage = () => (
  <MainLayout>
    <Section>
      <ContentContainer>
        <h1>No translation found</h1>
      </ContentContainer>
    </Section>
    <Section idName="events">
      Sorry 😔, we couldn’t find what you were looking for.
      <br />
      {process.env.NODE_ENV === "development" ? (
        <>
          <br />
          Try creating a page in src/pages/.
          <br />
        </>
      ) : null}
      <br />
      <Link to="/">Go home</Link>.
    </Section>
  </MainLayout>
);

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
